((window, localStorage) => {
    function getVisited() {
        return localStorage.getItem('install-prompt');
    }
    function setVisited() {
        localStorage.setItem('install-prompt', true);
    }
    const pwaPanel = document.querySelector('#pwa-install');
    window.addEventListener("beforeinstallprompt", (event) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        event.preventDefault();
        if(!getVisited()) {
            // Stash the event so it can be triggered later.
            window.deferredPrompt = event;
            // Update UI to notify the user they can add to home screen
            pwaPanel.classList.toggle("hidden", false);
            document.addEventListener('click', async (e) => {
                const pwaButton = e.target.closest('button.pwa-button');
                if (!pwaButton) return;
                // hide our user interface if user click on close
                if(pwaButton.id === "close-pwa") {
                    // Set visited if user close
                    setVisited();
                    pwaPanel.classList.toggle('hidden', true);
                }
                // hide our user interface if user click on close
                if(pwaButton.id === "install-pwa") {
                    const promptEvent = window.deferredPrompt;
                    if (!promptEvent) return;
                    // Show the prompt
                    promptEvent.prompt();
                    const result = await promptEvent.userChoice;
                    // Set visited if user dismiss
                    if(result.outcome !== "accepted") {
                        setVisited();
                    }
                    window.deferredPrompt = null;
                    pwaPanel.classList.toggle('hidden', true);
                }
            });
        }
    });
})(window, window.localStorage);